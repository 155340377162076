import React, { FC } from 'react';
import classnames from 'classnames';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';

import {
  CloudServerOutlined,
  PlusCircleOutlined,
  DatabaseOutlined,
  EditOutlined,
  LinkOutlined,
  ReloadOutlined,
  DownOutlined,
  UpOutlined,
  FileImageOutlined,
  SettingOutlined,
  FacebookFilled,
  InstagramFilled,
  InfoOutlined,
  UserOutlined,
  DeleteOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';

import {
  RiComputerLine,
  RiCheckDoubleLine,
  RiErrorWarningLine,
} from 'react-icons/ri';

import './Icon.scss';

const SpanWrapper: FC<any> = ({ className, IconComponent, ...rest }) => (
  <span className={ className }>
    <IconComponent { ...rest } />
  </span>
);

const icons = {
  server: CloudServerOutlined,
  plus: PlusCircleOutlined,
  api: DatabaseOutlined,
  edit: EditOutlined,
  link: LinkOutlined,
  reload: ReloadOutlined,
  chevronUp: UpOutlined,
  chevronDown: DownOutlined,
  file: FileImageOutlined,
  settings: SettingOutlined,
  facebook: FacebookFilled,
  instagram: InstagramFilled,
  info: InfoOutlined,
  user: UserOutlined,
  delete: DeleteOutlined,
  fullscreen: FullscreenOutlined,
  fullscreenExit: FullscreenExitOutlined,
  eyeInvisible: EyeInvisibleOutlined,
  eye: EyeOutlined,
  check: (props: any) => (
    <SpanWrapper { ...props } IconComponent={ RiCheckDoubleLine } />
  ),
  warning: (props: any) => (
    <SpanWrapper { ...props } IconComponent={ RiErrorWarningLine } />
  ),
  computer: (props: any) => (
    <SpanWrapper { ...props } IconComponent={ RiComputerLine } />
  ),
};

export type IconType = keyof typeof icons;

export const Icon: FC<IconProps> = ({ type, className, size, marginLeft, marginRight, ...rest }) => {
  const Element = (type in icons) ? icons[type] : (props: any) => <div { ...props }>{ type }</div>;
  const classNames = classnames(
    'pro-icon',
    className,
  );

  return (
    <Element
      className={ classNames }
      style={ {
        fontSize: size,
        ...(marginLeft && { marginLeft }),
        ...(marginRight && { marginRight }),
      } }
      { ...rest }
    />
  );
};

interface IconProps extends Omit<AntdIconProps, 'ref'> {
  type: IconType;
  className?: string;
  size?: number;
  marginLeft?: number;
  marginRight?: number;
  onClick?: () => any;
}
