import React, { FC, useMemo, memo } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import classnames from 'classnames';
import AntInput, { InputProps as AntInputProps } from 'antd/lib/input';
import 'antd/lib/input/style';

import { Modify } from 'utils/ts';
import { Message } from 'components/common';
import { getDefaultValidators, FieldValidator } from 'utils/validation';

import './Input.scss';

const InputRenderer: FC<InputRendererProps> = ({
  meta: { error, touched, submitError, dirtySinceLastSubmit },
  input,
  label,
  onChange,
  placeholder,
  className: passedClassName,
  required,
  height,
  ...rest
}) => {
  const { name } = input;
  const randomId = useMemo(() => (
    `input-${name}-${Math.floor(Math.random() * 10000)}`
  ), [name]);

  const errorMessage = touched && (error || (!dirtySinceLastSubmit && submitError));
  const className = classnames(
    'input',
    errorMessage ? 'has-error' : 'no-error',
    passedClassName,
  );

  const Component = input.type === 'password'
    ? AntInput.Password
    : AntInput;

  return (
    <div className={ className }>
      { label && (
        <label className="label" htmlFor={ randomId }>
          { `${label}${required ? '*' : ''}` }
        </label>
      ) }

      <Component
        id={ randomId }
        placeholder={ placeholder }
        required={ required }
        style={ { height } }
        { ...input }
        { ...rest }
        onChange={ event => {
          if (onChange) {
            onChange(event.target.value);
          }

          input.onChange(event);
        } }
      />

      <Message
        message={ errorMessage }
        isOpen={ !!errorMessage }
        marginTop={ 5 }
        simple
      />
    </div>
  );
};

export const Input: FC<InputProps> = memo(({
  name,
  type = 'text',
  validators: customValidators,
  label,
  required,
  minLength,
  min,
  ...rest
}) => {
  const validators = useMemo(() => (
    getDefaultValidators<string | number>({
      inputType: type,
      label,
      required,
      minLength,
      min,
    }).concat(customValidators || [])
  ), [type, label, required, minLength, customValidators, min]);

  const validator = (value: string | number | undefined, allValues: any, form: any) => (
    validators.reduce<string | void>((error, validate) => (
      error || validate(value, allValues, form)
    ), undefined)
  );

  return (
    <Field
      name={ name }
      type={ type }
      render={ InputRenderer }
      validate={ validator }
      required={ required }
      label={ label }
      { ... rest }
    />
  );
});

export type InputType = 'text' | 'time' | 'number' | 'email' | 'password'

interface CustomInputProps {
  name: string;
  onChange?: (value: string) => void;
  type?: InputType;
  defaultValue?: string | number;
  validators?: FieldValidator<string | number>[];
  label?: string;
  placeholder?: string;
  className?: string;
  minLength?: number;
  maxLength?: number;
}

interface InputRendererProps extends
  FieldRenderProps<string | number, HTMLInputElement>,
  Omit<InputProps, 'name'> {
}

export type InputProps = Modify<
Omit<AntInputProps, 'value'>,
CustomInputProps
>
