import validator from 'validator';
import { FormApi } from 'final-form';

import { InputType } from 'components/forms';

function isEmpty(value?: string | number | string[] | number[]) {
  const initialNotEmpty = value !== undefined && value !== null && value !== '';

  if (typeof value === 'string') {
    return !(initialNotEmpty && value.trim());
  }

  if (typeof value === 'number') {
    return !initialNotEmpty;
  }

  if (Array.isArray(value)) {
    return !(initialNotEmpty && value.length);
  }

  return true;
}

export const getDefaultValidators = <T extends string | number | string[] | number[]>({
  inputType,
  label,
  required,
  minLength,
  min,
}: Options): FieldValidator<T>[] => {
  const defaultValidators: FieldValidator<string | number | string[] | number[]>[] = [];

  if (required) {
    defaultValidators.push(rawValue => {
      if (isEmpty(rawValue)) {
        return `${label || 'Field'} is required`;
      }
    });

    if (inputType === 'email') {
      defaultValidators.push(rawValue => {
        // If inputType === 'email' we know the type of the rawValue will always be email
        const value = rawValue as string;

        if (!validator.isEmail(value || '')) {
          return 'Email address is in a wrong format';
        }
      });
    }

    if (inputType === 'number' && min !== undefined) {
      defaultValidators.push(rawValue => {
        // If inputType === 'email' we know the type of the rawValue will always be email
        const value = rawValue as number;

        if (value < +min) {
          return `Minimum value for ${label || 'Field'} is ${min}`;
        }
      });
    }

    if (minLength) {
      defaultValidators.push(rawValue => {
        // If minLength is provided we know the type of the rawValue will always be email
        const value = rawValue as string;

        if (isEmpty(value) || value.length < minLength) {
          return `${label || 'Field'} must be at least ${minLength} charactest long`;
        }
      });
    }
  }

  return defaultValidators;
};

interface Options {
  label?: string;
  inputType?: InputType | 'select';
  required?: boolean;
  minLength?: number;
  min?: string | number;
}

export type FieldValidator<T extends string | number | string[] | number[] = string> = (
  (value: T | undefined, allValues: any, form: FormApi<any>) => string | void
);
