import React, { FC, useMemo, memo } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import classnames from 'classnames';
import AntInput, { TextAreaProps as AntTextAreaProps } from 'antd/lib/input';
import 'antd/lib/input/style';

import { Modify } from 'utils/ts';
import { Message } from 'components/common';
import { getDefaultValidators, FieldValidator } from 'utils/validation';

import './TextArea.scss';

const TextAreaRenderer: FC<TextAreaRendererProps> = ({
  meta: { error, touched, submitError, dirtySinceLastSubmit },
  input,
  label,
  onChange,
  placeholder,
  className: passedClassName,
  required,
  height,
  ...rest
}) => {
  const { name } = input;
  const randomId = useMemo(() => (
    `text-area-${name}-${Math.floor(Math.random() * 10000)}`
  ), [name]);

  const errorMessage = touched && (error || (!dirtySinceLastSubmit && submitError));
  const className = classnames(
    'text-area',
    errorMessage ? 'has-error' : 'no-error',
    passedClassName,
  );

  return (
    <div className={ className }>
      { label && (
        <label className="label" htmlFor={ randomId }>
          { `${label}${required ? '*' : ''}` }
        </label>
      ) }

      <AntInput.TextArea
        id={ randomId }
        placeholder={ placeholder }
        required={ required }
        style={ { height } }
        { ...input }
        { ...rest }
        onChange={ event => {
          if (onChange) {
            onChange(event.target.value);
          }

          input.onChange(event);
        } }
      />

      <Message
        message={ errorMessage }
        isOpen={ !!errorMessage }
        marginTop={ 5 }
        simple
      />
    </div>
  );
};

export const TextArea: FC<TextAreaProps> = memo(({
  name,
  validators: customValidators,
  label,
  required,
  minLength,
  ...rest
}) => {
  const validators = useMemo(() => (
    getDefaultValidators<string>({
      inputType: 'text',
      label,
      required,
      minLength,
    }).concat(customValidators || [])
  ), [label, required, minLength, customValidators]);

  const validator = (value: string | undefined, allValues: any, form: any) => (
    validators.reduce<string | void>((error, validate) => (
      error || validate(value, allValues, form)
    ), undefined)
  );

  return (
    <Field
      name={ name }
      type="text"
      render={ TextAreaRenderer }
      validate={ validator }
      required={ required }
      label={ label }
      { ... rest }
    />
  );
});

interface CustomTextAreaProps {
  name: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  validators?: FieldValidator[];
  label?: string;
  placeholder?: string;
  className?: string;
  minLength?: number;
  maxLength?: number;
}

interface TextAreaRendererProps extends
  FieldRenderProps<string, HTMLTextAreaElement>,
  Omit<TextAreaProps, 'name'> {
}

export type TextAreaProps = Modify<
  Omit<AntTextAreaProps, 'value'>,
  CustomTextAreaProps
>
